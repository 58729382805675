@import '../../../../scss/theme-bootstrap';

.sticky-back-to-top-v2 {
  @include border-radius(50%);
  @include transition(opacity 0.4s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out);
  background-image: url('/media/images/global/bb-sticky-circle.png');
  background-repeat: no-repeat;
  background-size: 44px 44px;
  background-position: center;
  width: 44px;
  height: 44px;
  #{$rdirection}: 15px;
  text-align: center;
  top: 75%;
  position: fixed;
  z-index: 0;
  opacity: 0;
  @include breakpoint($medium-up) {
    #{$ldirection}: auto;
    #{$rdirection}: 40px;
  }
  img {
    width: 15px;
    height: 21px;
    #{$ldirection}: 0;
    position: relative;
    top: 10px;
  }
  &.sticky-back-to-top-new {
    .site-footer__wrap & {
      background: none;
      bottom: 170px;
      top: auto;
      @include breakpoint($medium-up) {
        width: 62px;
        height: 62px;
        bottom: 140px;
      }
      img {
        height: auto;
        width: auto;
        top: 0;
      }
    }
    @if $cr22 == true {
      .wrap.site-footer__wrap & {
        bottom: 20px;
      }
    }
  }
}

.sticky-header-opened .sticky-back-to-top-v2 {
  z-index: 999;
  opacity: 1;
  pointer-events: auto;
}
